import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import JobsHero from "../components/JobsHero"
import JobCard from "../components/JobCard"

function JobsPage({ data, ...rest }) {
  if (!data) return <p>No Jobs found</p>

  const openPositions = data.allMdx.edges.filter(
    ({ node }) => !node.frontmatter.upcoming
  )
  const upcomingPositions = data.allMdx.edges.filter(
    ({ node }) => node.frontmatter.upcoming
  )

  return (
    <>
      <Seo
        title="Jobs"
        description="No strong hierarchy, cooperation with global clients, dev-friendly processes, processes based on Scrum and Agile methodologies."
      />
      <JobsHero />
      <div className="container">
        <div className="pb-16 pt-8 flex flex-col">
          <h4
            id="open-positions"
            className="text-3.5xl md:text-4xl font-medium text-gray-900 mb-3 lg:mb-4"
          >
            Open positions
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 md:gap-x-5">
            {openPositions.map(({ node: { frontmatter: job } }) => (
              <JobCard key={job.title} job={job} />
            ))}
          </div>
          <div className="pt-16 lg:pt-20">
            <h4 className="text-3.5xl md:text-4xl font-medium text-gray-900 mb-3 lg:mb-4">
              Upcoming positions
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 md:gap-x-5">
              {upcomingPositions.map(({ node: { frontmatter: job } }) => (
                <JobCard key={job.title} job={job} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JobsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      filter: { fields: { collection: { eq: "jobs" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            title
            slug
            upcoming
            icon
          }
        }
      }
    }
  }
`
