import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import JobIcon from "./JobIcon"

function JobCard({ job }) {
  const { slug, title, icon, upcoming } = job

  return (
    <Link
      to={`/jobs/${slug}/`}
      title={title}
      className={classnames(
        "flex items-center h-40 bg-gray-200 hover:bg-primary-dark hover:text-white transition duration-200 ease-in p-5 lg:p-6",
        {
          "text-gray-700": upcoming,
          "text-black": !upcoming,
        }
      )}
    >
      <div className="flex items-center">
        <JobIcon name={icon} className="w-9 h-9 block" />
        <span className="font-regular text-2xl ml-3">{title}</span>
      </div>
    </Link>
  )
}

export default JobCard
