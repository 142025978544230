import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import PrimaryLinkButton from "./PrimaryLinkButton"

function JobsHero() {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(relativePath: { eq: "mobile/jobs-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "jobs-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div
      className="h-screen bg-white relative transition-all duration-75 overflow-hidden"
      style={{ maxHeight: 800 }}
    >
      <div className="absolute right-0 bottom-0 top-0 left-0 pointer-events-none flex items-end lg:items-center">
        <div className="w-full max-w-sm h-auto lg:hidden ml-auto">
          <GatsbyImage
            image={data.mobileBackground.childImageSharp.gatsbyImageData}
            alt=""
          />
        </div>
        <div className="w-full mx-auto max-w-screen-2xl hidden lg:block">
          <div className="w-full max-w-4xl h-auto ml-auto opacity-75">
            <GatsbyImage
              image={data.desktopBackground.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="relative h-full flex flex-col">
        <div className="container flex-1 flex flex-col justify-center">
          <h1 className="text-4.5xl sm:text-4.5xl md:text-6xl leading-tight font-medium text-gray-900">
            Join our team!
          </h1>
          <p className="max-w-3xl text-lg leading-snug md:text-2xl font-medium text-gray-700 pt-2">
            No strong hierarchy, cooperation with global clients, dev-friendly
            processes, processes based on Scrum and Agile methodologies.
          </p>
          <div className="pt-6 pb-10">
            <PrimaryLinkButton
              to={`/jobs/#open-positions`}
              title="join us today"
              size="large"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobsHero
