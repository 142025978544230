import React from "react"

const noopState = {
  icon: "div",
  defaultProps: {},
}

function JobIcon({ name, ...props }) {
  const [{ defaultProps, icon: Icon }, setIconComponent] = React.useState(
    noopState
  )
  React.useEffect(() => {
    async function load() {
      try {
        const icon = await import(`../svg/jobs/${name}.svg`)
        setIconComponent({
          icon: icon.default,
          defaultProps: icon.defaultProps,
        })
      } catch (error) {
        setIconComponent(noopState)
      }
    }
    load()
  }, [name])
  return (
    <Icon {...defaultProps} {...props} />
  )
}

export default JobIcon;
